<template>
  <b-card
    class="device-swap p-2"
    no-body
  >
    <label>Filter by:</label>
    <b-input-group
      class="w-100"
    >
      <v-select
        v-model="option1Selected"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="filterByOptions1"
        class="select-size-lg mb-2 w-50"
      />
      <v-select
        v-model="option2Selected"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="filterByOptions2"
        class="select-size-lg mb-2 w-50"
      />
    </b-input-group>
    <label>Select Devices to Update:</label>
    <v-select
      v-model="devicesSelected"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      multiple
      :options="devices"
      class="select-size-lg mb-2"
    />

    <label>Property:</label>
    <json-editor-vue
      v-model="twin"
      v-bind="editorOptions"
      class="mb-2"
    />

    <b-alert
      variant="success"
      :show="updateResponse.length > 0"
    >
      {{ updateResponse }}
    </b-alert>

    <b-button
      variant="primary"
      class="m-1 p-1"
      :disabled="isBusy"
      @click="updateBulk"
    >
      Update
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BInputGroup, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import JsonEditorVue from 'json-editor-vue'
import assetsLogStore from '../../assets/assetsLogStore'

export default {
  components: {
    vSelect,
    BCard,
    BButton,
    BInputGroup,
    BAlert,
    JsonEditorVue,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-assets-list'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetsLogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()
    const twin = ref({})
    const allAssets = ref([])
    const lines = ref([])
    const buildings = ref([])
    const customers = ref([])
    const sites = ref([])
    const updateResponse = ref('')
    const option1Selected = ref([
      { title: '*' },
    ])
    const option2Selected = ref(['*'])
    const filterByOptions1 = ref([
      { title: 'Customer' },
      { title: 'Site' },
      { title: 'Building' },
      { title: 'Line' },
      { title: '*' },
    ])
    const filterByOptions2 = ref(['*'])
    const devices = ref([])
    const devicesSelected = ref([])
    const allDevices = ref([])
    const isBusy = ref(false)
    const editorOptions = {
      mode: 'text',
      mainMenuBar: false,
    }

    const updateBulk = () => {
      if (JSON.stringify(twin.value) === '{}' || devicesSelected.value.length === 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Please select devices and properties to update',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      const twinProps = JSON.parse(twin.value)
      const data = {
        twin: twinProps,
        devices: devicesSelected.value,
      }
      isBusy.value = true
      store
        .dispatch('app-assets-list/bulkUpdate', data)
        .then(response => {
          isBusy.value = false
          updateResponse.value = response.data
          toast({
            component: ToastificationContent,
            props: {
              title: 'Bulk Update Successful',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          isBusy.value = false
          console.log('Error on updating devices', e)
          updateResponse.value = 'Error on updating devices'
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating bulk',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllAssets = () => {
      isBusy.value = true
      store
        .dispatch('app-assets-list/fetchAllAssets')
        .then(response => {
          isBusy.value = false
          const { data } = response
          const dataFiltered = data.filter(a => a.deviceID !== 'None')
          allAssets.value = dataFiltered
          allDevices.value = [...new Set(dataFiltered.map(a => a.deviceID))]
          devices.value = [...['*'], ...allDevices.value]
          lines.value = [...new Set(dataFiltered.map(a => a.lineName)), ...['*']]
          buildings.value = [...new Set(dataFiltered.map(a => a.buildingName)), ...['*']]
          customers.value = [...new Set(dataFiltered.map(a => a.customerName)), ...['*']]
          sites.value = [...new Set(dataFiltered.map(a => a.siteName)), ...['*']]
        })
        .catch(e => {
          isBusy.value = 
          console.log('Error fetching assets list', e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching assets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchAllAssets()

    watch(option1Selected, val => {
      if (val.title === 'Customer') {
        filterByOptions2.value = customers.value
      } else if (val.title === 'Site') {
        filterByOptions2.value = sites.value
      } else if (val.title === 'Building') {
        filterByOptions2.value = buildings.value
      } else if (val.title === 'Line') {
        filterByOptions2.value = lines.value
      } else {
        filterByOptions2.value = ['*']
      }
    })
    watch([option1Selected, option2Selected], ([option1, val]) => {
      devicesSelected.value = []
      if (option1.title === 'Customer') {
        const deviceList = allAssets.value.filter(a => a.customerName === val)
        devices.value = [...['*'], ...deviceList.map(a => a.deviceID)]
      } else if (option1.title === 'Site') {
        const deviceList = allAssets.value.filter(a => a.siteName === val)
        devices.value = [...['*'], ...deviceList.map(a => a.deviceID)]
      } else if (option1.title === 'Building') {
        const deviceList = allAssets.value.filter(a => a.buildingName === val)
        devices.value = [...['*'], ...deviceList.map(a => a.deviceID)]
      } else if (option1.title === 'Line') {
        const deviceList = allAssets.value.filter(a => a.lineName === val)
        devices.value = [...['*'], ...deviceList.map(a => a.deviceID)]
      } else {
        devices.value = [...['*'], ...allDevices.value]
      }
    })

    watch(devicesSelected, val => {
      if (val[0] === '*') {
        const selectAll = [...devices.value]
        const index = selectAll.indexOf('*')
        if (index > -1) { // only splice array when item is found
          selectAll.splice(index, 1) // 2nd parameter means remove one item only
        }
        devicesSelected.value = selectAll
      }
    })

    return {
      filterByOptions1,
      filterByOptions2,
      option1Selected,
      option2Selected,
      devices,
      devicesSelected,
      twin,
      editorOptions,
      updateResponse,
      isBusy,
      updateBulk,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
